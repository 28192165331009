
@import "../../../src/styles/scss/variables.scss";

.home-page {
    height: 100vh;
    width: 100vw;
    position: relative;
    transition: all 500ms;
    border-bottom: 1px solid $primary-black;
    box-shadow: 0 2px 3px #eee;

    &__dropped {
        border-bottom: none;
        box-shadow: none;
        background-image: url('../../assets/images/wall.jpg');
        background-size: cover;

        &:before {
            content: "";
            height: 100vh;
            width: 100vw;
            position: absolute;
            background: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.1) 100%);
        }
    }

   

    &__slogan {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h2, h4 {
            color: #fff  !important;
        }
    }

    &__btn-play {
        bottom: 50px;
        position: absolute;
        top: auto;
        left: 45%;
        background-color: transparent;
        border-radius: 10px;
        //color: $primary-black;
        color: #fff;
        text-transform: uppercase;
        padding: 4px 16px;
        font-weight: 500;
        border: none;
        outline: none;
    }
}