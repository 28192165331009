@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/functions";

@import "../../styles/scss/site-base";

.contacts {
  a {
    color: black !important;
  }
  a.address {
    text-decoration: underline;
  }
  a.address:hover {
      color: $red !important;
  }
}
.submit-button {
  background-color: $red !important;
  border-color: $red !important;
  color: white   !important;
}

.icon-red {
  color: $red !important;
}
