#services {

}

.service {
    display: flex;
    flex-direction: row;
    padding: 54px 0;

    @media screen and (max-width: 900px){
        flex-direction: column;
        padding: 16px 0;
    }
    &__block {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 24px;
        width: 33.3%;

        @media screen and (max-width: 900px){
            padding: 24px 8px;
            width: 100%;
        }

        img {
            width: 50px;
            height: auto;

            @media screen and (max-width: 900px){
                width: 30px;
            }
        }
        span {
            font-size: 16px;
        }
    }
    &__info {
        padding: 0 24px;
    }
}