@import "../../../src/styles/scss/variables.scss";

#vacancies {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
}

.career-card { 
    border: 1px solid $red;
    border-radius: 5px;
    box-shadow: 0 3px 6px $red-16;
    width: 200px;
    padding: 16px;
    transition: all 500ms;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px) {
        width: 90%;
        // flex-direction: row;
        justify-content: space-between;
    }

    &_margin {
        margin: 8px;
    }

    &:hover {
        transform: scale(1.05);
    }

    &__img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 50%;
            height: auto;
        }

        @media screen and (max-width: 600px) {
            img {
                width: 30%;
                height: auto;
            }
        }
    }
    &__info {
        text-align: center;
        padding: 8px;
        h2 {
            color: $red;
            font-size: 16px;
        }
        p {
            margin: 0;
            padding: 0;
            color: $primary-black;
            font-size: 14px;
        }
    }
}