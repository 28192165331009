@import "../../styles/scss/variables.scss";
@import "~bootstrap/scss/bootstrap";

.portfolio-carousel {
    a {
        color: $red;
        &:hover {
            color: $primary-black;
        }
    }
    &__arrow {
        width: 25px;
        height: auto;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        text-align: center;
        height: 100%;
        width: 70%;
        padding: 16px;
        margin-bottom: 24px;
        min-height: 350px;

        img {
            width: 150px;
            height: 100px;
            padding: 16px;
            margin-bottom: 16px;
            object-fit: contain;
        }
    }
    .carousel-caption {
        p, h3 {
            color: $primary-black;
        }
    }
    .carousel-indicators {
        li {
            border-top: 5px solid $red;
        }
    }
}