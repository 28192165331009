@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/nav";
@import "../../styles/scss/site-blocks";
@import "../../styles/scss/site-base";

.site-navbar {

  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  top: 0;
  width: 100%;
  // background: $white;
  border-bottom: rgba($white,.7);

  .site-logo {
    position: relative;
    left: 0;
    top: -5px;
    img {
      height: 72px;
    }
  }

  .site-navigation {

    .site-menu {

      margin-bottom: 0;
      .active {
        // > a {
        color: $red ;
        display: inline-block;
        padding: 5px 20px;
        // }
      }
      a {
        text-decoration: none!important;
        display: inline-block;
      }
      > li {
        display: inline-block;
        > a {
          padding: 5px 20px;
          color: $gray-700;
          font-size: 16px;
          display: inline-block;
          text-decoration: none!important;
          font-weight: bold;

          &:hover {
            color: $red;
          }
        }
        > span {
          padding: 5px 20px;
          color: $gray-700;
          font-size: 16px;
          display: inline-block;
          text-decoration: none!important;
          font-weight: bold;

          &:hover {
            color: $red;
          }
        }
      }
      .has-children {
        position: relative;
        > a {
          position: relative;
          padding-right: 20px;
          &:before {
            position: absolute;
            content: "\e313";
            font-size: 16px;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-family: 'icomoon';
          }
        }
        .dropdown {
          visibility: hidden;
          opacity: 0;
          top: 100%;
          position: absolute;
          text-align: left;
          border-top: 2px solid $red;
          box-shadow: 0 2px 10px -2px rgba(0,0,0,.1);

          padding: 0px 0;
          margin-top: 20px;
          margin-left: 0px;
          background: $white;
          transition: 0.2s 0s;

          &.arrow-top {
            position: absolute;
            &:before {
              bottom: 100%;
              left: 20%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
            }
            &:before {
              border-color: rgba(136, 183, 213, 0);
              border-bottom-color: $white;
              border-width: 10px;
              margin-left: -10px;
            }
          }


          a {
            // font-size: 16px;
            text-transform: none;
            letter-spacing: normal;
            transition: 0s all;
            color: $black;
          }
          .active {
            // > a {
            color: $red !important;
            // }
          }
          > li {
            list-style: none;
            padding: 0;
            margin: 0;
            min-width: 60px;
            > a {
              padding: 9px 20px;
              display: block;
              &:hover {
                background: lighten($gray-200, 2%);
                color: $black;
              }
            }
            > span {
              padding: 9px 20px;
              display: block;
              &:hover {
                background: lighten($gray-200, 2%);
                color: $black;
              }
            }

            &.has-children {
              > a {
                &:before {
                  content: "\e315";
                  right: 20px;
                }
              }
              > .dropdown, > ul {
                left: 100%;
                top: 0;
              }
              &:hover, &:active, &:focus {
                > a {
                  // background: lighten($gray-4, 2%);
                  // color: $gray-1;
                }
              }
            }
          }
        }

        &:hover, &:focus, &:active {
          > a {
            color: $red;
          }
        }
        &:hover, &:focus, &:active {
          cursor: pointer;
          > .dropdown {
            transition-delay: 0s;
            margin-top: 0px;
            visibility: visible;
            opacity: 1;


          }
        }
      }
    }
  }
}

.header-logo {
  z-index: 2;
}

.nav-inverted {
  .nav-link {
    font-family: 'Roboto';
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 28px;
    line-height: 28px;
    color: #fff !important;
  }
}

// mobile menu

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: $white;
  height: calc(100vh);

  transform: translateX(110%);
  box-shadow: -10px 0 20px -10px rgba(0,0,0,.1);
  transition: .3s all ease-in-out;

  .offcanvas-menu & {
    transform: translateX(0%);
  }

  .site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;

    .site-mobile-menu-close {
      float: right;
      margin-top: 8px;
      span {
        font-size: 30px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 0px;
        line-height: 1;
        cursor: pointer;
        transition: .3s all ease;
        &:hover {
          // color: $gray-1;
        }
      }
    }
    .site-mobile-menu-logo {
      float: left;
      margin-top: 10px;
      margin-left: 0px;
      a {
        display: inline-block;
        text-transform: uppercase;
        img {
          max-width: 70px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 20px 20px 20px;

    height: calc(100vh - 52px);
    padding-bottom: 150px;

  }
  .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    // float: left;

    // width: 100%;
    // height: 100%;
    position: relative;

    a {
      padding: 10px 20px;
      display: block;
      position: relative;
      color: $gray-900;
      &:hover {
        color: $red;
      }
    }
    li {
      position: relative;
      display: block;
      .active {
        // > a {
        color: $red ;
        // }
      }
    }
    .arrow-collapse {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 20;
      width: 36px;
      height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      &:hover {
        background: $gray-100;
      }
      &:before {
        font-size: 12px;
        z-index: 20;
        font-family: "Roboto";
        content: "\f078";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-180deg);
        transition: .3s all ease;
      }
      &.collapsed {
        &:before {
          transform: translate(-50%, -50%);
        }
      }
    }
    > li {
      display: block;
      position: relative;
      float: left;
      width: 100%;

      > a {
        padding-left: 20px;
        font-size: 20px;
      }
      > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        > li {
          display: block;
          > a {
            padding-left: 40px;
            font-size: 16px;
          }
          > ul {
            padding: 0;
            margin: 0;
            > li {
              display: block;
              > a {
                font-size: 16px;
                padding-left: 60px;
              }
            }
          }
        }
      }
    }

    &[data-class="social"] {
      float: left;
      width: 100%;
      margin-top: 30px;
      padding-bottom: 5em;
      > li {
        width: auto;
        &:first-child {
          a {
            padding-left: 15px!important;
          }
        }
      }
    }

  }


}

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
  & + .site-blocks-cover {
    // margin-top: 140px;
    // margin-top: 96px;
  }
  .site-navbar {
    transition: .3s all ease;
  }
  .site-navbar {
    border-bottom: 1px solid rgba($white, .2);
    .site-menu-toggle {
      color: $white;
    }
    .site-logo {
      a {
        color: $white;
      }
    }
    .site-menu {
      > li {
        > a {
          color: rgba($white, .7)!important;
          &:hover, &.active {
            color: $white!important;
          }
        }
        > div {
          color: rgba($white, .7)!important;
          &:hover, &.active {
            color: $white!important;
          }
        }
      }
    }
  }
  &.is-sticky {

    .site-navbar {
      background: $white;
      border-bottom: 1px solid transparent;
      box-shadow: 4px 0 20px -5px rgba(0,0,0,.1);
      .site-menu-toggle {
        color: $black;
      }
      .site-logo {
        a {
          color: $red ;
        }
      }
      .site-menu {
        > li {
          > a {
            color: $black!important;
            &:hover, &.active {
              color: $red !important;
            }
          }
        }
      }
    }
  }
  .shrink {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }
}

.button-link {
  cursor: pointer;
}


