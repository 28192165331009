@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import "assets/fonts/icomoon/style.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}