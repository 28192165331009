@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/functions";

a {
  transition: .3s all ease;
  &:hover {
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.border-2 {
  border-width: 2px;
}

.text-black {
  color: $black!important;
}

.text-white-opacity-05 {
  color: rgba($white, .5);
}
.text-black-opacity-05 {
  color: rgba($black, .5);
}

.btn {
  font-size: 16px;
  border-radius: 30px;
  padding: 10px 30px;

  &:hover, &:active, &:focus {
    outline: none;
    box-shadow: none!important;
  }
  &.btn-primary {
    background: $danger!important;
    border-color: $danger!important;
    color: $white;
    &:hover {
      background: $black;
      border-color: $black;
      color: $white;
    }
    &.btn-black--hover {
      &:hover {
        background: lighten($black, 40%);
        color: $white;
      }
    }
  }

}

.line-height-1 {
  line-height: 1!important;
}
.bg-black {
  background: $black;
}


.form-control {
  height: 43px;
  border-radius: 30px;
  font-family: 'Roboto', sans-serif;
  &:active, &:focus {
    border-color: $primary;
  }
  &:hover, &:active, &:focus {
    box-shadow: none!important;
  }
}
.site-footer {
  padding: 4em 0;
  background: lighten($black, 20%);
  @include media-breakpoint-up(md) {
    padding: 8em 0; 
  }
  .border-top {
    border-top: 1px solid rgba(255,255,255,.1)!important;
  }
  p {
    color: lighten($black, 45%);
  }
  h2,h3,h4,h5 {
    color: $white;
  }
  a {
    color: lighten($black, 45%);
    &:hover {
      color: lighten($black, 50%);
    }
  }
  ul {
    li {
      margin-bottom: 10px;
    }
  }
  .footer-heading {
    font-size: 16px;
    color: $white;
  }
}

.footer-subscribe {
  .btn {
    height: 43px;
    line-height: 1;
    background: $danger!important;
    color: $black!important;
  }
}

.socials {
  a {
    color: $red;
  }
}

.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-about {
  text-align: left;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
}